//登陆显示
import React from 'react'
import { Form, Input, Button,  Card ,notification} from 'antd'
import { MailTwoTone, LockTwoTone, } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import {useDispatch,useSelector } from 'react-redux'

import axios from 'axios'
import './index.css'


export default function Login() {
    const userstate = useSelector((state) => {
        return state
    })
    const dispatch = useDispatch()
    const openmsg = (type,title,inf,time)=> {
        if(type==='error')time=30
        if(type==='warning')time=30
        if(type==='success')time=10
        notification[type]({
          message: title,
          description:inf,
          duration: time,
        });
      };
    const navigate = useNavigate();
    const sha1 = require('js-sha1');
    const onFinish = (values) => {
    axios.get(userstate.Backurl.url+'/IEEEbackend/login.php?!='+(values.useremail+'|'+sha1(values.password))).then(
        response => {
            console.log(response);
            if(response.data['success']===1){
            
                dispatch({
                    type: 'login',
                    data: {Email:values.useremail,Admin:response.data['admin'],User:response.data['user'],Committee:response.data['committee'],Committeepower:response.data['committeepower'],Userpower:response.data['userpower'],Name:response.data['name'],Phone:(response.data['phone']),Password:sha1(values.password)}
                })
                
                openmsg('success','','Login successfully! ');
                if(response.data['admin']==='1')navigate("/admin");
                else if(response.data['committee']==='1')navigate("/committee");
                else navigate("/user");
                
                 
                
            }
            else switch(response.data['error']){
                case 1:openmsg('error','','Incorrect password');break;
                case 2:openmsg('error','','Login account does not exist!');break;
                case 3:openmsg('warning','','Account has not been activated yet. Please contact Adminstrator.s1317086@monmouth.edu'); break;
                default:break;
            }

        },
        error => {
            openmsg('error','','Can not find server');
            
        }
    )

        //
    };
    
    const singup = () => {
        navigate("/sign_up");
    };
    const rpsword = () => {
        navigate("/reset_psword");
    };
  return <Card title='IEEE SMCS Project Management System' className="login-form">
  <Form
      name="normal_login"
      onFinish={onFinish}
      initialValues={{
          remember: true,
      }}
     >
         
      <Form.Item
          name="useremail"
          rules={[
              {
                  required: true,
                  message: 'input your E-mail!',
              },{ pattern: /^[a-zA-Z 0-9-_.':;,!/@$%^<>?"*()~`=+\n]+$/,message: "Do not enter special characters, Except -_.':;,!@$%^<>?\"*()~`=+ ",},
          ]}
      >
      <Input prefix={<MailTwoTone className="site-form-item-icon" />} placeholder="E-mail" />
      </Form.Item>
      <Form.Item
          name="password"
          rules={[
              {
                  required: true,
                  message: 'input your Password!',
              },
              { 
                pattern: /^\w+$/,
                message: 'only numbers, letters, underscores!',
               },
          ]}
      >
          <Input.Password
              prefix={<LockTwoTone className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
          />
      </Form.Item>
        
      <Form.Item> 
          <Button type="primary" htmlType="submit" className="login-form-button" block>
             Login
          </Button>
          <Button type="link" onClick={rpsword}>Forget Password</Button>
          <Button type="link" onClick={singup}>Sign up</Button>
      </Form.Item>
  </Form>
</Card>;
}
