import React, { Component ,lazy,Suspense} from 'react';
/*

import Rpsword from './pages/Rpsword';
import Singup from './pages/Singup';
import Admin from './pages/Admin';
import User from './pages/User';
import NotFound from './pages/NotFound';
*/
import {
  Routes,
  Route,
} from 'react-router-dom'
import Login from './pages/Login';
import NotFound from './pages/NotFound'
import Loading from './components/Loading';



const Rpsword = lazy(()=> import('./pages/Rpsword'))
const Singup= lazy(()=> import('./pages/Singup'))
const Admin = lazy(()=> import('./pages/Admin'))
const User= lazy(()=> import('./pages/User'))
const Committee= lazy(()=> import('./pages/Committee'))
//const NotFound= lazy(()=> import('./pages/NotFound'))





export default class App extends Component {
  render() {
    return <div>
      <Suspense fallback={<Loading/>}>
      <Routes>
        
        <Route index element = {<Login/>}/> 
        <Route path="/login" element = {<Login/>}/>
        <Route path="/reset_psword" element = {<Rpsword/>}/>
        <Route path="/sign_up" element = {<Singup/>}/>

        <Route path="/committee/*" element = {< Committee />}/>
        <Route path="/admin/*" element = {< Admin />}/>
        <Route path="/user/*" element = {<User/>}/>
        

        <Route path="*" element={<NotFound />} />

      </Routes>
      </Suspense>
    </div>;
  }
}
